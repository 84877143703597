import { when } from 'mobx'
import { apiV1 } from '../../utils'
import { applySnapshot, getSnapshot, types } from 'mobx-state-tree'
import { fromPromise } from 'mobx-utils'
import {
  EnergyResourceModel,
  PriceRegionModel,
  CurrencyModel,
  MaterialTypesModel,
  MaterialModel,
  WorkModel,
  MaterialPriceModel,
  MaterialCoefficientModel,
  WorkPriceModel,
  WorkCoefficientModel,
  CurrencyFormModel,
  CurrencySelectModel,
  SupplierRegionsModel,
} from '../models'
import { PhysicalRegionModel } from '../models/referenceBooks/PhysicalRegionModel'

const ReferenceBooksStore = types
  .model('ReferenceBooksStore')
  .props({
    _energyResourceList: types.array(EnergyResourceModel),
    _priceRegionList: types.array(PriceRegionModel),
    _physicalRegionList: types.array(PhysicalRegionModel),
    _currecyList: types.array(CurrencyModel),
    _currencyList: types.array(CurrencySelectModel),
    _materialTypesList: types.array(MaterialTypesModel),
    _workTypesList: types.array(MaterialTypesModel),
    _materialsList: types.array(MaterialModel),
    _worksList: types.array(WorkModel),
    _materialPricesList: types.array(MaterialPriceModel),
    _workPricesList: types.array(WorkPriceModel),
    _supplierRegionsList: types.array(SupplierRegionsModel),
  })
  .actions(self => {
    const ENERGY_RESOURCE_URL = 'EnergyResources'
    const PRICE_REGIONS_URL = 'PriceRegions'
    const CURRENCIES_URL = 'Currencies'
    const MATERIAL_TYPES_URL = 'MaterialTypes'
    const WORK_TYPES_URL = 'WorkTypes'
    const MATERIALS_URL = 'Materials'
    const WORKS_URL = 'Works'
    const MATERIAL_PRICES_URL = 'MaterialPrices'
    const WORK_PRICES_URL = 'WorkPrices'
    const MATERIAL_COEFFICIENTS = 'MaterialCoefficients'
    const WORK_COEFFICIENTS = 'WorkCoefficients'
    const CURRENCY_RATES_URL = 'CurrencyRates'
    const PHYSICAL_REGIONS_URL = 'PhysicalRegions'
    const SUPPLIER_REGIONS_URL = 'SupplierRegions'
    const SUPPLIER_URL = 'Suppliers'

    const setEnergyResourceList = resources => {
      applySnapshot(self._energyResourceList, resources)
    }
    const setPriceRegionList = (regions, languages) => {
      applySnapshot(self._priceRegionList, regions, languages)
    }
    const setPhysicalRegionList = countryId => {
      applySnapshot(self._physicalRegionList, countryId)
    }
    const setSupplierRegions = countryId => {
      applySnapshot(self._supplierRegionsList, countryId)
    }

    const setCurrencyList = currencies => {
      applySnapshot(self._currecyList, currencies)
    }
    const setCurrenciesList = currencies => {
      applySnapshot(self._currencyList, currencies)
    }
    const setMaterialTypes = languages => {
      applySnapshot(self._materialTypesList, languages)
    }
    const setWorkTypesList = languages => {
      applySnapshot(self._workTypesList, languages)
    }
    const setMaterials = materials => {
      applySnapshot(self._materialsList, materials)
    }
    const setWorks = works => {
      applySnapshot(self._worksList, works)
    }
    const setMaterialPrices = (prices, languages) => {
      applySnapshot(self._materialPricesList, prices, languages)
    }
    const setWorkPrices = prices => {
      applySnapshot(self._workPricesList, prices)
    }

    const getEnergyResourceList = (language = null, countryId = null, typeId = null, regionId = null) => {
      const getEnergyResouresRequest = fromPromise(
        apiV1.get(`${ENERGY_RESOURCE_URL}`, { params: { culture: language, countryId: countryId, tid: typeId, rid: regionId } }),
      )
      when(() => {
        getEnergyResouresRequest.case({
          fulfilled: response => {
            setEnergyResourceList(response.data)
            return true
          },
        })
      })

      return getEnergyResouresRequest
    }

    const addEnergyResource = async data => {
      const addResourceRequest = fromPromise(apiV1.post(`${ENERGY_RESOURCE_URL}`, data))
      return addResourceRequest
    }

    const updateEnergyResource = async newData => {
      const updateResourceRequest = fromPromise(apiV1.put(`${ENERGY_RESOURCE_URL}`, newData))
      return updateResourceRequest
    }

    const deleteEnergyResource = async id => {
      const deleteResourceRequest = fromPromise(apiV1.delete(`${ENERGY_RESOURCE_URL}/${id}`, { params: { id } }))
      return deleteResourceRequest
    }

    const getCurrencyList = countryId => {
      const getCurrencyRequest = fromPromise(apiV1.get(`${CURRENCY_RATES_URL}`, { params: { countryId } }))
      when(() => {
        getCurrencyRequest.case({
          fulfilled: response => {
            setCurrencyList(response.data)
            return true
          },
        })
      })

      return getCurrencyRequest
    }

    const getCurrenciesList = countryId => {
      const getCurrencyRequest = fromPromise(apiV1.get(`${CURRENCIES_URL}`, { params: { countryId } }))
      when(() => {
        getCurrencyRequest.case({
          fulfilled: response => {
            setCurrenciesList(response.data)
            return true
          },
        })
      })

      return getCurrencyRequest
    }

    const addCurrency = async data => {
      const request = fromPromise(apiV1.post(`${CURRENCIES_URL}`, data))
      return request
    }

    const updateCurrency = async newData => {
      const request = fromPromise(apiV1.put(`${CURRENCIES_URL}`, newData))
      return request
    }

    const updateCurrencyRatio = async newData => {
      const request = fromPromise(apiV1.put(`${CURRENCY_RATES_URL}`, newData))
      return request
    }

    const deleteCurrency = async id => {
      const request = fromPromise(apiV1.delete(`${CURRENCIES_URL}/${id}`, { params: { id } }))
      return request
    }

    const getPriceRegionList = (countryId, languages = null) => {
      const getRegionsRequest = fromPromise(
        apiV1.get(`${PRICE_REGIONS_URL}`, {
          headers: {
            culture: languages,
          },
          params: { countryId },
        }),
      )
      when(() => {
        getRegionsRequest.case({
          fulfilled: response => {
            setPriceRegionList(response.data)
            return true
          },
        })
      })

      return getRegionsRequest
    }

    const getPhysicalRegionList = country => {
      const getPhysicalRegionsRequest = fromPromise(
        apiV1.get(`${PHYSICAL_REGIONS_URL}`, {
          params: { country },
        }),
      )
      when(() => {
        getPhysicalRegionsRequest.case({
          fulfilled: response => {
            setPhysicalRegionList(response.data)
            return true
          },
        })
      })

      return getPhysicalRegionsRequest
    }

    const getSupplierRegionsList = country => {
      const getSupplierRegionsRequest = fromPromise(
        apiV1.get(`${SUPPLIER_REGIONS_URL}`, {
          params: { country },
        }),
      )
      when(() => {
        getSupplierRegionsRequest.case({
          fulfilled: response => {
            setSupplierRegions(response.data)
            return true
          },
        })
      })

      return getSupplierRegionsRequest
    }

    const addSupliers = async data => {
      const request = fromPromise(apiV1.post(`${SUPPLIER_URL}`, data))
      return request
    }

    const updateSuppliers = async data => {
      const request = fromPromise(apiV1.put(`${SUPPLIER_URL}`, data))
      return request
    }

    const deleteSuppliers = async id => {
      const request = fromPromise(apiV1.delete(`${SUPPLIER_URL}/${id}`))
      return request
    }

    const updatePhysicalRegion = async data => {
      const request = fromPromise(apiV1.put(`${PHYSICAL_REGIONS_URL}`, data))
      return request
    }

    const getPriceRegion = id => {
      const request = fromPromise(apiV1.get(`${PRICE_REGIONS_URL}/${id}`))
      return request
    }

    const addPriceRegion = async data => {
      const request = fromPromise(apiV1.post(`${PRICE_REGIONS_URL}`, data))
      return request
    }

    const updatePriceRegion = async newData => {
      const request = fromPromise(apiV1.put(`${PRICE_REGIONS_URL}`, newData))
      return request
    }

    const addSupplierRegions = async newData => {
      const request = fromPromise(apiV1.post(`${SUPPLIER_REGIONS_URL}`, newData))
      return request
    }

    const updateSupplierRegions = async newData => {
      const request = fromPromise(apiV1.put(`${SUPPLIER_REGIONS_URL}`, newData))
      return request
    }

    const deleteSupplierRegions = async id => {
      const request = fromPromise(apiV1.patch(`${SUPPLIER_REGIONS_URL}/${id}`))
      return request
    }

    const changeMainRegion = async id => {
      const request = fromPromise(apiV1.patch(`${PRICE_REGIONS_URL}/${id}/ChangeHead`))
      return request
    }

    const deletePriceRegion = async id => {
      const request = fromPromise(apiV1.patch(`${PRICE_REGIONS_URL}/${id}/Hide`))
      return request
    }

    const getWorkTypesList = (languages = null) => {
      const request = fromPromise(
        apiV1.get(`${WORK_TYPES_URL}`, {
          headers: {
            culture: languages,
          },
        }),
      )
      when(() => {
        request.case({
          fulfilled: response => {
            setWorkTypesList(response.data)
            return true
          },
        })
      })

      return request
    }

    const getWorkType = id => {
      const request = fromPromise(apiV1.get(`${WORK_TYPES_URL}/${id}`))

      return request
    }

    const getMaterialTypesList = (languages = null) => {
      const request = fromPromise(
        apiV1.get(`${MATERIAL_TYPES_URL}`, {
          headers: {
            culture: languages,
          },
        }),
      )
      when(() => {
        request.case({
          fulfilled: response => {
            setMaterialTypes(response.data)
            return true
          },
        })
      })

      return request
    }

    const getMaterialType = id => {
      const request = fromPromise(apiV1.get(`${MATERIAL_TYPES_URL}/${id}`))
      return request
    }

    const addWorkType = async data => {
      const request = fromPromise(apiV1.post(`${WORK_TYPES_URL}`, data))
      return request
    }

    const addMaterialType = async data => {
      const request = fromPromise(apiV1.post(`${MATERIAL_TYPES_URL}`, data))
      return request
    }

    const updateMaterialType = async newData => {
      const request = fromPromise(apiV1.put(`${MATERIAL_TYPES_URL}`, newData))
      return request
    }

    const updateWorkType = async newData => {
      const request = fromPromise(apiV1.put(`${WORK_TYPES_URL}`, newData))
      return request
    }

    const deleteMaterialType = async id => {
      const request = fromPromise(apiV1.delete(`${MATERIAL_TYPES_URL}/${id}`, { params: { id } }))
      return request
    }

    const deleteWorkType = async id => {
      const request = fromPromise(apiV1.delete(`${WORK_TYPES_URL}/${id}`, { params: { id } }))
      return request
    }

    const getMaterialsList = params => {
      const request = fromPromise(apiV1.get(`${MATERIALS_URL}`, { params }))
      when(() => {
        request.case({
          fulfilled: response => {
            setMaterials(response.data)
            return true
          },
        })
      })

      return request
    }

    const getMaterial = id => {
      const request = fromPromise(apiV1.get(`${MATERIALS_URL}/${id}`))
      return request
    }

    const addMaterial = async data => {
      const request = fromPromise(apiV1.post(`${MATERIALS_URL}`, data))
      return request
    }

    const updateMaterial = async newData => {
      const request = fromPromise(apiV1.put(`${MATERIALS_URL}`, newData))
      return request
    }

    const deleteMaterial = async id => {
      const request = fromPromise(apiV1.delete(`${MATERIALS_URL}/${id}`, { params: { id } }))
      return request
    }
    const addWork = async data => {
      const request = fromPromise(apiV1.post(`${WORKS_URL}`, data))
      return request
    }

    const updateWork = async newData => {
      const request = fromPromise(apiV1.put(`${WORKS_URL}`, newData))
      return request
    }

    const deleteWork = async id => {
      const request = fromPromise(apiV1.delete(`${WORKS_URL}/${id}`, { params: { id } }))
      return request
    }

    const getWorksList = params => {
      const request = fromPromise(apiV1.get(`${WORKS_URL}`, { params }))
      when(() => {
        request.case({
          fulfilled: response => {
            setWorks(response.data)
            return true
          },
        })
      })

      return request
    }

    const getWork = id => {
      const request = fromPromise(apiV1.get(`${WORKS_URL}/${id}`))
      return request
    }

    const getMaterialPricesList = (params, languages = null) => {
      const request = fromPromise(
        apiV1.get(`${MATERIAL_PRICES_URL}`, {
          params: { ...params, culture: languages },
        }),
      )
      when(() => {
        request.case({
          fulfilled: response => {
            setMaterialPrices(response.data)
            return true
          },
        })
      })

      return request
    }
    const getWorkPricesList = params => {
      const request = fromPromise(apiV1.get(`${WORK_PRICES_URL}`, { params }))
      when(() => {
        request.case({
          fulfilled: response => {
            setWorkPrices(response.data)
            return true
          },
        })
      })

      return request
    }

    const addMaterialPrice = async data => {
      const request = fromPromise(apiV1.post(`${MATERIAL_PRICES_URL}`, data))
      return request
    }

    const updateMaterialPrice = async newData => {
      const request = fromPromise(apiV1.put(`${MATERIAL_PRICES_URL}`, newData))
      return request
    }

    const deleteMaterialPrice = async id => {
      const request = fromPromise(apiV1.delete(`${MATERIAL_PRICES_URL}/${id}`, { params: { id } }))
      return request
    }

    const addMaterialCoefficients = async data => {
      const request = fromPromise(apiV1.post(`${MATERIAL_COEFFICIENTS}`, data))
      return request
    }

    const updateMaterialCoefficients = async newData => {
      const request = fromPromise(apiV1.put(`${MATERIAL_COEFFICIENTS}`, newData))
      return request
    }

    const deleteMaterialCoefficients = async id => {
      const request = fromPromise(apiV1.delete(`${MATERIAL_COEFFICIENTS}/${id}`))
      return request
    }

    const addWorkPrice = async data => {
      const request = fromPromise(apiV1.post(`${WORK_PRICES_URL}`, data))
      return request
    }

    const updateWorkPrice = async newData => {
      const request = fromPromise(apiV1.put(`${WORK_PRICES_URL}`, newData))
      return request
    }

    const deleteWorkPrice = async id => {
      const request = fromPromise(apiV1.delete(`${WORK_PRICES_URL}/${id}`, { params: { id } }))
      return request
    }

    const addWorkCoefficients = async data => {
      const request = fromPromise(apiV1.post(`${WORK_COEFFICIENTS}`, data))
      return request
    }

    const updateWorkCoefficients = async newData => {
      const request = fromPromise(apiV1.put(`${WORK_COEFFICIENTS}`, newData))
      return request
    }

    const deleteWorkCoefficients = async id => {
      const request = fromPromise(apiV1.delete(`${WORK_COEFFICIENTS}/${id}`))
      return request
    }

    const exportMaterialCoefficientsAsExcel = params => {
      const request = fromPromise(apiV1.get(`${MATERIAL_COEFFICIENTS}/Export`, { params }))
      return request
    }

    const importMaterialCoefficientsAsExcel = (data, countryId) => {
      const request = fromPromise(apiV1.put(`${MATERIAL_COEFFICIENTS}/Import`, data, { params: { countryId } }))
      return request
    }

    const exportWorkPricesAsExcel = (culture = 'en-US', fileType = 'excel') => {
      const request = fromPromise(apiV1.get(`${WORK_COEFFICIENTS}/Export`, { params: { culture, type: fileType } }))
      return request
    }
    const importWorkPricesAsExcel = (data, culture = 'en-US', fileType = 'excel') => {
      const request = fromPromise(apiV1.put(`${WORK_COEFFICIENTS}/Import`, data))
      return request
    }

    return {
      setEnergyResourceList,
      getEnergyResourceList,
      getPriceRegionList,
      getPriceRegion,
      getPhysicalRegionList,
      getSupplierRegionsList,
      addSupplierRegions,
      updateSupplierRegions,
      deleteSupplierRegions,
      addSupliers,
      updateSuppliers,
      deleteSuppliers,
      updatePhysicalRegion,
      addEnergyResource,
      updateEnergyResource,
      deleteEnergyResource,
      getCurrencyList,
      getCurrenciesList,
      addCurrency,
      updateCurrency,
      updateCurrencyRatio,
      deleteCurrency,
      getMaterialTypesList,
      getMaterialType,
      addMaterialType,
      updateMaterialType,
      deleteMaterialType,
      getMaterialsList,
      getWorksList,
      getWorkTypesList,
      getWorkType,
      addWorkType,
      deleteWorkType,
      updateWorkType,
      getMaterialPricesList,
      getWorkPricesList,
      // exportMaterialPricesAsExcel,
      exportMaterialCoefficientsAsExcel,
      // importMaterialPricesAsExcel,
      importMaterialCoefficientsAsExcel,
      exportWorkPricesAsExcel,
      importWorkPricesAsExcel,
      addPriceRegion,
      updatePriceRegion,
      changeMainRegion,
      deletePriceRegion,
      addWork,
      updateWork,
      deleteWork,
      updateMaterialPrice,
      updateWorkPrice,
      addMaterial,
      updateMaterial,
      deleteMaterial,
      getWork,
      getMaterial,
      addMaterialPrice,
      deleteMaterialPrice,
      addMaterialCoefficients,
      updateMaterialCoefficients,
      deleteMaterialCoefficients,
      addWorkPrice,
      deleteWorkPrice,
      addWorkCoefficients,
      updateWorkCoefficients,
      deleteWorkCoefficients,
    }
  })
  .views(self => ({
    get energyResourceList() {
      return getSnapshot(self._energyResourceList)
    },
    get priceRegionList() {
      return getSnapshot(self._priceRegionList)
    },
    get physicalRegionList() {
      return getSnapshot(self._physicalRegionList)
    },
    get supplierRegionsList() {
      return getSnapshot(self._supplierRegionsList)
    },
    get currencyList() {
      return getSnapshot(self._currecyList)
    },
    get currenciesList() {
      return getSnapshot(self._currencyList)
    },
    get materialTypesList() {
      return getSnapshot(self._materialTypesList)
    },
    get workTypesList() {
      return getSnapshot(self._workTypesList)
    },
    get materialsList() {
      return getSnapshot(self._materialsList)
    },
    get worksList() {
      return getSnapshot(self._worksList)
    },
    get materialPricesList() {
      return getSnapshot(self._materialPricesList)
    },
    get workPricesList() {
      return getSnapshot(self._workPricesList)
    },
  }))

export default ReferenceBooksStore
